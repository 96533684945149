import { setStatePayload } from "@/config/store/StoreItemsModule/helpers"

export default () => ({
  SET_MESSAGES: setStatePayload("messages"),
  SET_TEMPLATES: setStatePayload("templates"),
  SET_TEMPLATE: setStatePayload("template"),
  SET_PLAN: setStatePayload("plan"),
  SET_DIRTY: setStatePayload("isDirty"),
  ADD_MESSAGE: (state, message) => state.messages.push(message),
  CHANGE_OTA: (state, ota) => {
    state.templates = []
    state.template = {}
    state.plan = {}
    state.filters = {
      shop_id: null,
      car_class_id: null,
      all_shops: false,
      all_car_classes: false,
      ota
    }
  }
})
