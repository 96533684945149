import { i18n, api } from "@/config"
import { findLast } from "lodash-es"
import { parseCarOptions, preparePlanParams, parseTemplates, prepareTemplate, mapFilters } from "./helpers"

export default () => ({
  async FETCH_TEMPLATES({ commit, state: { filters, pagination } }, customParams = {}) {
    const { headers } = customParams
    const params = {
      filters: mapFilters(filters),
      pagination,
      ...customParams
    }

    const response = await api.get("plan_settings/templates", { params, headers })
    const { items, meta } = response.data.data
    commit("SET_TEMPLATES", parseTemplates(items))
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  async FETCH_PLAN({ commit, state: { filters } }, customParams = {}) {
    const { headers } = customParams
    const params = {
      filters: mapFilters(filters),
      ...customParams
    }

    const response = await api.get("plan_settings/plans", { params, headers })
    const plan = response.data.data
    plan.car_options = parseCarOptions(plan)
    commit("SET_PLAN", plan)
  },

  async CREATE_PLAN({ commit, state: { filters } }, plan) {
    const params = {
      filters: mapFilters(filters),
      ...preparePlanParams(plan)
    }

    const response = await api.post("plan_settings/plans", params)
    const created_plan = response.data.data
    created_plan.car_options = parseCarOptions(created_plan)
    commit("SET_PLAN", created_plan)
    commit("SET_MESSAGES", [])
    commit("ADD_MESSAGE", {
      type: "success",
      title: i18n.t("plan_settings.basic_settings.alert_messages.successfully_changed")
    })
  },

  async UPDATE_PLAN({ commit, state: { filters } }, plan) {
    const params = {
      filters: mapFilters(filters),
      ...preparePlanParams(plan)
    }

    const response = await api.put(`plan_settings/plans/${plan.id}`, params)
    const updated_plan = response.data.data
    updated_plan.car_options = parseCarOptions(updated_plan)
    commit("SET_PLAN", updated_plan)
    commit("SET_MESSAGES", [])
    commit("ADD_MESSAGE", {
      type: "success",
      title: i18n.t("plan_settings.basic_settings.alert_messages.successfully_changed")
    })
  },

  async CREATE_TEMPLATE({ commit, state: { plan, filters } }, name) {
    const preparedTemplate = prepareTemplate({ plan })

    const params = {
      filters: mapFilters(filters),
      pagination: { _disabled: true },
      ...preparePlanParams(preparedTemplate),
      template_name: name
    }

    const response = await api.post("plan_settings/templates", params)
    const { items, meta } = response.data.data
    const templates = parseTemplates(items)
    const currentTemplate = findLast(templates, ({ template_name }) => template_name === name)
    commit("SET_TEMPLATES", templates)
    commit("SET_TEMPLATE", currentTemplate)
    commit("SET_PAGINATION_DATA", meta.pagination)
    commit("ADD_MESSAGE", {
      type: "success",
      title: i18n.t("plan_settings.basic_settings.alert_messages.template_created", { template_name: name })
    })
  },

  async UPDATE_TEMPLATE({ commit, state: { plan, filters } }, template) {
    const { car_options, insurances, template_name, id } = template
    const preparedTemplate = prepareTemplate({
      plan,
      dependencies: { car_options, insurances },
      method: "update"
    })

    const params = {
      filters: mapFilters(filters),
      pagination: { _disabled: true },
      ...preparePlanParams(preparedTemplate),
      id,
      template_name,
      template: true
    }

    const response = await api.put(`plan_settings/templates/${id}`, params)
    const { items, meta } = response.data.data
    const templates = parseTemplates(items)
    commit("SET_TEMPLATES", templates)
    commit("SET_PAGINATION_DATA", meta.pagination)
  },

  async DELETE_TEMPLATE({ commit, dispatch, state: { filters } }, id) {
    commit("SET_LOADING", true)
    const params = {
      filters: mapFilters(filters)
    }

    await api.delete(`plan_settings/templates/${id}`, { params })
    dispatch("FETCH_TEMPLATES")
  }
})
