import { MAX_OPTIONS, FEE_OPTIONS, PERIOD_SPECIFIED_FEE, STANDARD, SELECTABLE } from "@/config/constants"
import { find, get, map, cloneDeep } from "lodash-es"

export const parseCarOptions = plan => {
  return map(plan.car_options, item => {
    if (Array.isArray(item.fee)) {
      return { ...item, fees: item.fee, fee: 0 }
    }

    return { ...item, fees: [] }
  })
}

export const parseTemplates = templates =>
  map(templates, template => {
    template.car_options = parseCarOptions(template)
    return template
  })

const prepareCarOptions = plan => {
  return map(plan.car_options, item => ({
    id: item.id,
    sources_car_option_id: item.sources_car_option.id,
    selectability_type: item.selectability_type,
    fee_type: prepareFeeType(item),
    fee: prepareFee(item),
    max_fee: prepareMaxFee(item)
  }))
}

const prepareInsurances = plan => {
  return map(plan.insurances, item => ({
    id: item.id,
    sources_insurance_id: item.sources_insurance.id,
    insurance_type: item.insurance_type,
    selectability_type: item.selectability_type,
    fee_type: prepareFeeType(item, true),
    fee: prepareFee(item, true),
    max_fee: prepareMaxFee(item, true)
  }))
}

export const preparePlanParams = plan => ({
  ...plan,
  car_options: prepareCarOptions(plan),
  insurances: prepareInsurances(plan),
  sources_cancellation_fee_rule_id: plan.sources_cancellation_fee_rule?.id,
  importance: plan.importance?.id
})

const prepareFeeType = (item, insurances = false) => {
  if (insurances) return item.selectability_type === SELECTABLE ? item.fee_type : null

  return [STANDARD, SELECTABLE].includes(item.selectability_type) ? item.fee_type : null
}

const prepareFee = (item, insurances = false) => {
  if (insurances && item.selectability_type !== SELECTABLE) return null

  if (item.fee_type === PERIOD_SPECIFIED_FEE) {
    return Array.isArray(item.fees) && item.fees.length > 0 ? item.fees : []
  }

  return FEE_OPTIONS.includes(item.fee_type) ? item.fee : null
}

const prepareMaxFee = (item, insurances = false) => {
  if (insurances && item.selectability_type !== SELECTABLE) return null

  if (MAX_OPTIONS.includes(item.fee_type) && [STANDARD, SELECTABLE].includes(item.selectability_type)) {
    return item.max_fee
  }

  return null
}

export const mapFilters = ({ ota, ...filters }) => ({
  ota_id: ota.id,
  ...filters
})

export const prepareTemplate = ({ plan, dependencies = {}, method = "create" }) => {
  /**
   * The function replaces the ids of dependencies (for now car options,
   * insurances as declared below) from "plan" with ids from "template"
   *
   * If method is "update", it fetches matched dependency id from template
   * If method is "create", it sets undefined
   *
   * In case of adding a new dependency:
   * 1) Declare it in the object (as shown below)
   * 2) Create `matchDependencyId` function for matching ids from necessary scope
   * 3) Reinitialize dependency array in the `preparedTemplate` with matched ids
   */

  const { car_options, insurances } = dependencies
  const preparedTemplate = cloneDeep(plan)

  preparedTemplate.car_options = map(preparedTemplate.car_options, item => ({
    ...item,
    id: method === "update" ? matchCarOptionId(car_options, item.sources_car_option.id) : undefined
  }))

  preparedTemplate.insurances = map(preparedTemplate.insurances, item => ({
    ...item,
    id: method === "update" ? matchInsuranceId(insurances, item.sources_insurance.id, item.insurance_type) : undefined
  }))

  return preparedTemplate
}

export const matchCarOptionId = (scope, id) => get(find(scope, { sources_car_option: { id } }), "id")
export const matchInsuranceId = (scope, id, insurance_type) =>
  get(find(scope, { sources_insurance: { id }, insurance_type }), "id")

export const getPlanFromTemplate = (plan, template) => {
  const matchedCarOptions = template.car_options.map(item => ({
    ...item,
    id: matchCarOptionId(plan.car_options, item.sources_car_option.id)
  }))

  const matchedInsurances = template.insurances?.map(item => ({
    ...item,
    id: matchInsuranceId(plan.insurances, item.sources_insurance.id, item.insurance_type)
  }))

  return {
    ...cloneDeep(template),
    id: plan.id,
    optional_export_status: "not_set",
    included_export_status: "not_set",
    template: false,
    template_name: null,
    car_options: matchedCarOptions,
    insurances: matchedInsurances,
    basic_price: matchTemplateBasicPrice(plan, template)
  }
}

const matchTemplateBasicPrice = (plan, template) => {
  const planInsurances = Object.keys(plan.basic_price)
  const hasOneInsurance = planInsurances.length === 1

  if (hasOneInsurance) {
    const targetInsurance = planInsurances[0]
    const templateBasicPrices = Object.values(template.basic_price)[0]

    return cloneDeep({ [targetInsurance]: templateBasicPrices })
  } else {
    return cloneDeep(template.basic_price)
  }
}
