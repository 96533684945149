import StoreItemsModule from "@/config/store/StoreItemsModule"
import { withLoading } from "@/config/store/StoreItemsModule/helpers"
import mutationsBuild from "./mutations"
import actionsBuild from "./actions"
import { store as rootStore } from "@/config"
import { find } from "lodash"

const BASE_URI = "/plan_settings/basic_settings"

const store = new StoreItemsModule({
  baseURI: BASE_URI,
  withFilters: {
    all_shops: false,
    all_car_classes: false,
    shop_id: null,
    car_class_id: null,
    insurance: null,
    ota: find(rootStore.getters.translatedOtaList, { id: 1 })
  },
  withPagination: true
})

// item = plan, template = template, items = plans with template flag true
export const defaultState = {
  templates: [],
  template: {},
  plan: {},
  messages: [],
  isDirty: false
}

store.mergeState(defaultState)

store.mergeActions(actionsBuild({ baseURL: BASE_URI }), withLoading)

store.mergeMutations(mutationsBuild())

export default store
