<template lang="pug">
  .plan-settings
    AppNestedRoutesTabs(
      :routes="routes"
      :default-prevented="isDirty"
    )
</template>

<script>
  import shopsModule from "@/config/store/matching/shop"
  import pricesCarClassesModule from "@/config/store/price_management/car_class"
  import basicSettingsModule from "@/config/store/price_management/plan_settings/basic_settings"
  import withStoreModule from "@/mixins/withStoreModule"

  const shopsMixin = withStoreModule(shopsModule, {
    name: "shopMatching",
    readers: { shopsLoading: "loading", shops: "items" },
    actions: { fetchShops: "FETCH_ITEMS" }
  })

  const pricesCarClassesMixin = withStoreModule(pricesCarClassesModule, {
    name: "planSettingsPricesCarClasses",
    readers: { carClassesLoading: "loading", carClasses: "items" },
    actions: { fetchCarClasses: "FETCH_ITEMS" }
  })

  const basicSettingsMixin = withStoreModule(basicSettingsModule, {
    resetState: true,
    name: "basicSettings",
    readers: ["isDirty"]
  })

  export default {
    components: {
      AppNestedRoutesTabs: () => import("@/components/AppNestedRoutesTabs")
    },

    mixins: [shopsMixin, pricesCarClassesMixin, basicSettingsMixin],

    computed: {
      routes() {
        return [
          {
            name: "BasicSettings",
            title: this.$t("header.title.basic_settings")
          },
          {
            name: "ByPeriod",
            title: this.$t("header.title.by_period")
          }
        ]
      }
    },

    data() {
      return {
        jalanPoints: []
      }
    }
  }
</script>

<style lang="sass" scoped></style>
